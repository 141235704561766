import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainLayout from "./pages/MainLayout";
import LoginForm from "./pages/LoginPage";
import LandingPage from "./pages/LandingPage";
import RegisterPage from "./pages/RegisterPage";
import Dashboard from "./pages/DashboardPage";
import FeedPage from "./pages/FeedPage";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import SettingsPage from './pages/SettingsPage';
import SugarImpactAnalyzer from './pages/SugarProductsAnalyserPage';

const App: React.FC = () => (
    <AuthProvider>
        <Router>
            <Routes>
                <Route path="/" element={<MainLayout />} >
                    <Route index element={<LandingPage />} />
                    <Route path="login" element={<LoginForm />} />
                    <Route path="register" element={<RegisterPage />} />
                    <Route path="products" element={<SugarImpactAnalyzer />} />

                    {/* Protected routes */}
                    <Route element={<ProtectedRoute />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="feed" element={<FeedPage />} />
                        <Route path="settings" element={<SettingsPage />} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    </AuthProvider>
);

export default App;
