import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterData, registerSchema, registerUser } from "../services/registerService";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";

const RegisterPage: React.FC = () => {
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showAgreement, setShowAgreement] = useState(false);
    const [agreed, setAgreed] = useState(false);

    // Initialize React Hook Form with Yup schema validation
    const { register, handleSubmit, formState: { errors } } = useForm<RegisterData>({
        resolver: yupResolver(registerSchema),
    });

    // Form submission handler
    const onSubmit = async (formData: RegisterData) => {
        if (!agreed) {
            setError("Please agree to the community support statement.");
            return;
        }
        const response = await registerUser(formData);
        if (response.success) {
            setMessage(response.message);
            setError(null);
        } else {
            setError(response.message);
            setMessage(null);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-blue-50">
            <div className="relative w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
                <button
                    onClick={() => window.location.href = '/'}
                    className="absolute top-4 left-4 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full shadow-md hover:bg-gray-300 transition ease-in-out duration-150"
                    aria-label="Back"
                >
                    <ChevronLeftIcon className="w-6 h-6 text-gray-600"/>
                </button>

                <div className="flex justify-center mb-6">
                    <img src="/brain-logo.svg" alt="Stop Sugar Icon" className="w-20 h-20"/>
                </div>

                <h2 className="text-2xl font-bold text-center text-blue-600 mb-6">Create an Account</h2>

                {/* Success/Error Messages */}
                {message && <p className="text-center text-green-600 mb-4">{message}</p>}
                {error && <p className="text-center text-red-600 mb-4">{error}</p>}

                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    {/* Username Input */}
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                        <input
                            type="text"
                            {...register("username")}
                            className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.username && <p className="text-red-600 text-sm mt-1">{errors.username.message}</p>}
                    </div>

                    {/* Email Input */}
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            {...register("email")}
                            className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.email && <p className="text-red-600 text-sm mt-1">{errors.email.message}</p>}
                    </div>

                    {/* Password Input */}
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                {...register("password")}
                                className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? "👁️" : "🙈"}
                            </button>
                        </div>
                        {errors.password && <p className="text-red-600 text-sm mt-1">{errors.password.message}</p>}
                    </div>

                    {/* Agreement Checkbox with Popup */}
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="agreement"
                            className="mr-2"
                            checked={agreed}
                            onChange={() => setAgreed(!agreed)}
                        />
                        <label htmlFor="agreement" className="text-sm text-gray-700">
                            I agree to support the community.{" "}
                            <button
                                type="button"
                                onClick={() => setShowAgreement(true)}
                                className="text-blue-600 underline"
                            >
                                Read more
                            </button>
                        </label>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full py-3 mt-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-200"
                    >
                        Sign Up
                    </button>
                </form>

                <p className="text-center text-gray-600 mt-4">
                    Already have an account? <a href="/login" className="text-blue-600 hover:underline">Log In</a>
                </p>
            </div>

            {/* Agreement Popup */}
            {showAgreement && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg text-gray-700">
                        <h3 className="text-xl font-bold text-blue-600 mb-4">Community Support Agreement</h3>
                        <p className="mb-4">
                            Quitting sugar is a challenging journey, and we believe that a strong, supportive community
                            can make a huge difference. By joining, you agree to be a part of this support system.
                            Encourage, empathize, and share your journey to help others stay on track.
                        </p>
                        <p className="mb-4">
                            Remember, your words and actions can have a profound impact on others who are struggling
                            with similar habits. Let’s create a positive space where everyone feels motivated and supported!
                        </p>
                        <button
                            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700"
                            onClick={() => setShowAgreement(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegisterPage;