import React from "react";

const LandingPage: React.FC = () => {
    return (
        <div className="font-sans text-gray-900">
            {/* Header */}
            <header className="flex justify-between items-center px-6 py-4 bg-white shadow-md">
                {/* Logo Section */}
                <div className="flex items-center space-x-2">
                    <img src="/brain-logo.svg" alt="Stop Sugar Icon" className="w-10 h-10"/> {/* Adjusted size */}
                    <span className="text-2xl font-bold tracking-wide text-gray-800">Sugarless</span>
                </div>


                {/* Action Buttons */}
                <div className="flex space-x-4 items-center">
                    <a href="/login"
                       className="px-4 py-2 rounded-full font-medium text-gray-700 border border-gray-300 bg-gray-100 hover:bg-gray-200 transition-all duration-200 shadow-sm">
                        Login
                    </a>
                    <a href="/register"
                       className="px-4 py-2 rounded-full font-medium bg-orange-500 text-white hover:bg-orange-600 transition-all duration-200 shadow-md">
                        Sign Up
                    </a>
                </div>
            </header>

            {/* Hero Section */}
            <section className="bg-cover bg-center h-screen relative"
                     style={{backgroundImage: "url('/health_cover.png')"}}>
                <div
                    className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-center text-white px-4">
                    <h1 className="text-4xl md:text-5xl font-bold mb-4">Be sugarless. Like water, my friend.</h1>
                    <p className="text-lg md:text-xl mb-6">- Inspired by Bruce Lee</p>
                    <div className="flex space-x-4">
                        <a href="#signup"
                           className="px-6 py-3 bg-green-500 rounded-full text-white font-semibold hover:bg-green-600">Get
                            Started</a>
                        <a href="#features" className="text-lg underline hover:text-green-400">Learn More</a>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section id="features" className="py-12 px-6 bg-gray-100">
                <h2 className="text-3xl font-bold text-center mb-8">Why you should be here?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 text-center">
                    <Feature icon="📅" title="Daily Check-Ins" description="Stay accountable with simple daily logs."/>
                    <Feature icon="🏆" title="Set Achievable Goals"
                             description="Define clear, long-term goals to track your sugar-free journey and stay motivated every step of the way."/>
                    <Feature icon="📈" title="Track Your Progress"
                             description="Visualize your journey and celebrate milestones."/>
                    <Feature icon="👥" title="Community Support"
                             description="Connect with others for motivation and advice."/>
                </div>
            </section>

            <section className="py-12 px-6 bg-green-500 text-white text-center">
                <h2 className="text-3xl font-bold mb-4">Your health journey starts here.</h2>
                <a href="/register" className="px-6 py-3 bg-orange-500 rounded-full font-semibold hover:bg-orange-600">Join
                    the Movement</a>
            </section>

            {/* Footer */}
            <footer className="py-8 px-6 bg-gray-800 text-gray-200">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="font-bold mb-2">Follow Us</h3>
                        <div className="flex space-x-4">
                            <a href="#" className="hover:text-white">Facebook</a>
                            <a href="#" className="hover:text-white">Instagram</a>
                            <a href="#" className="hover:text-white">Twitter</a>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <p className="text-center mt-8">© 2024 Sugarless. All rights reserved.</p>
            </footer>
        </div>
    );
};

// Helper Components
const Feature: React.FC<{ icon: string, title: string, description: string }> = ({icon, title, description}) => (
    <div className="flex flex-col items-center">
        <div className="text-4xl mb-4">{icon}</div>
        <h3 className="font-semibold">{title}</h3>
        <p className="text-gray-700">{description}</p>
    </div>
);

const Testimonial: React.FC<{ name: string, quote: string }> = ({name, quote}) => (
    <div className="flex-shrink-0 w-64 bg-gray-100 p-6 rounded-lg shadow-md">
        <p className="mb-4">“{quote}”</p>
        <p className="text-sm font-semibold">{name}</p>
    </div>
);

const Step: React.FC<{ icon: string, title: string, description: string }> = ({icon, title, description}) => (
    <div className="flex flex-col items-center">
        <div className="text-4xl mb-4">{icon}</div>
        <h3 className="font-semibold">{title}</h3>
        <p className="text-gray-700">{description}</p>
    </div>
);

export default LandingPage;
