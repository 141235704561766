// src/services/sugarCalculator.ts

export interface SugarMetrics {
    name: string; // Name for easier identification
    sugar: number; // grams of sugar
    calories: number; // kcal
    energy?: { kJ: number; kcal: number }; // Optional energy in kJ and kcal
    fat?: number; // grams of fat
    saturatedFat?: number; // grams of saturated fat
    carbohydrates?: number; // grams of carbs
    protein?: number; // grams of protein
    salt?: number; // grams of salt
    volume?: number; // Optional for drinks (ml)
    weight?: number; // Optional for solids (g)
}

// Constants
const GRAMS_PER_TEASPOON = 4;
const HANDFUL_WEIGHT = 5; // 1 handful = ~5g of sugar
const KCAL_TO_KJ = 4.184;

// Updated helper functions

export const calculateTeaspoons = (sugar: number): number => {
    return Math.round((sugar / GRAMS_PER_TEASPOON) * 100) / 100; // Rounded to 2 decimal places
};

export const calculateHandfuls = (sugar: number): number => {
    return Math.round(sugar / HANDFUL_WEIGHT);
};

export const convertCaloriesToKJ = (calories: number): number => {
    return Math.round(calories * KCAL_TO_KJ);
};

export const determineSugarLevel = (sugar: number): string => {
    if (sugar <= 10) return "Low";
    if (sugar <= 25) return "Moderate";
    if (sugar <= 40) return "High";
    return "Very High";
};

export const getInsulinDemand = (sugar: number): string => {
    if (sugar <= 10) return "Low Demand";
    if (sugar <= 25) return "Moderate Demand";
    if (sugar <= 40) return "High Demand";
    return "Very High Demand";
};

// Main calculation function
export const calculateSugarImpact = (metrics: SugarMetrics) => {
    const { sugar, calories } = metrics;

    // Calculate basic metrics
    const teaspoons = calculateTeaspoons(sugar);
    const handfuls = calculateHandfuls(sugar);
    const kJ = metrics.energy ? metrics.energy.kJ : convertCaloriesToKJ(calories);
    const sugarLevel = determineSugarLevel(sugar);
    const insulinDemand = getInsulinDemand(sugar);

    // Return the original metrics with calculated values
    return {
        ...metrics,
        teaspoons,
        handfuls,
        kJ,
        sugarLevel,
        insulinDemand,
    };
};