// src/services/checkInService.tsx
import api from "./api";

// Define the structure of the check-in response from the backend
export interface CheckInResponse {
    user_id?: number;
    full_name?: string;
    username?: string;
    success: boolean;
    message: string;
    streak?: number;
    totalCheckIns?: number;
    checkedInToday?: boolean;
    lastCheckInDate?: string;
}



export interface StreakResponse {
    success: boolean;
    streak: number;
}

export interface ErrorResponse {
    success: boolean;
    message: string;
}

export const checkInUser = async (): Promise<CheckInResponse> => {
    try {
        const response = await api.post('/checkin');

        return {
            success: true,
            message: response.data.message || "Checked in successfully",
            streak: response.data.streak,
            totalCheckIns: response.data.total_checkins,
            checkedInToday: response.data.checked_in_today,
            lastCheckInDate: response.data.last_check_in
        }
    } catch (error) {
        throw new Error( 'Failed to check in. Please try again.');
    }
};

export const getCurrentStreak = async (): Promise<CheckInResponse> => {
    try {
        const response = await api.get('/me');

        return {
            user_id: response.data.id,
            full_name: response.data.full_name,
            success: true,
            message: response.data.message || "Checked in successfully",
            streak: response.data.streak,
            totalCheckIns: response.data.total_checkins,
            checkedInToday: response.data.checked_in_today,
            lastCheckInDate: response.data.last_check_in
        }
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
};
