import React, { useEffect } from 'react';
import ProgressBar from "../components/ProgressBarComponent";
import DailyCheckIn from "../components/DailyCheckinComponent";
import { useAuth } from "../contexts/AuthContext";
import StreakProgress from '../components/StreakComponent';
import EnergyDayChart from '../components/DailyFlowComponent';
import HealthBenefitsTimeline from '../components/HealthBenefitsTimelineComponent';

const DashboardPage: React.FC = () => {
    const { user } = useAuth();
    const streaksMilestones = [7, 14, 21, 30];

    const nextMilestone = (streak: number) => {
        for (let milestone of streaksMilestones) {
            if (streak < milestone) {
                return milestone;
            }
        }
        return streaksMilestones[streaksMilestones.length - 1] + 7;
    }

    return (
        <div className="min-h-screen bg-blue-50 p-4 font-sans flex flex-col">
            
            {/* Main Content Grid */}
            <main className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">

                {/* Column 1: Daily Check-In and Streak Progress */}
                <div className="md:col-span-1 lg:col-span-1 space-y-4">
                    <DailyCheckIn />
                    <StreakProgress 
                        streak={parseInt(user?.streak || '0')} 
                        nextMilestone={nextMilestone(parseInt(user?.streak || '0'))} 
                    />
                </div>

                {/* Full-Width Energy Rhythm on Larger Screens */}
                <div className="lg:col-span-1">
                    <EnergyDayChart />
                </div>

                 {/* Column 2: Health Benefits Timeline */}
                <div className="md:col-span-2 lg:col-span-1 space-y-4">
                    <div className='bg-white p-6 rounded-lg shadow-md w-full max-w-3xl mx-auto'>
                    <h2 className="text-xl font-semibold mb-4">Personal Goals</h2>
                    <ProgressBar 
                        label={"Reach 30 Days Sugar-Free"} 
                        currentValue={parseInt(user?.streak || '0')} 
                        targetValue={30} 
                    />
                    <ProgressBar 
                        label={'New challenge starting soon: "Sugar-Free Weekend!"'} 
                        currentValue={0} 
                        targetValue={2} 
                    />
                    </div>
                    <HealthBenefitsTimeline 
                        streak={parseInt(user?.streak || '0')} 
                    />
                </div>

            </main>

            {/* Footer */}
            <footer className="mt-10 text-center text-gray-600">
                <a href="feed" className="hover:underline">About Us</a> |
                <a href="#" className="hover:underline mx-2">Privacy Policy</a> |
                <a href="#" className="hover:underline">Terms of Service</a> |
                <a href="#" className="hover:underline ml-2">Contact Support</a>
            </footer>
        </div>
    );
};

export default DashboardPage;