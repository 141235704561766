import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {User, UserContextType} from "../types/User";
import {CheckInResponse} from "../services/checkInService";


export const UserContext = createContext<UserContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [token, setToken] = useState<string | null>(
        localStorage.getItem('token')
    );

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedToken = localStorage.getItem('token');

        if ( storedToken) {
            setUser(JSON.parse(storedUser || 'null'));
            setToken(storedToken);
        }
    }, []);

    const updateUser = (userData: User) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        console.log('updateUser---', userData);
    }

    const login = (userData: User, authToken: string) => {
        // setUser(userData);
        setToken(authToken);
        // localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('token', authToken);
    };

    const logout = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    };

    return (
        <UserContext.Provider value={{ user, updateUser, token, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
