import React, { useEffect, useState } from 'react';

interface Milestone {
    days: string;
    physical: string;
    mental: string;
    sleep: string;
    icon: string;
    challenges: string;
    tips: string;
    range: [number, number]; // [minStreak, maxStreak]
}

const milestones: Milestone[] = [
    {
        days: '1-7 Days',
        physical: 'Stable energy levels, reduced sugar cravings.',
        mental: 'Slight mood improvements.',
        sleep: 'Better sleep patterns, feeling less restless.',
        icon: '⚡',
        challenges: 'Increased cravings, irritability, and frequent thoughts about sugary foods.',
        tips: 'Stay hydrated, keep healthy snacks on hand, and remind yourself of your long-term goals. Try distracting yourself when cravings hit.',
        range: [1, 7],
    },
    {
        days: '8-14 Days',
        physical: 'Improved digestion, noticeable energy boost.',
        mental: 'Enhanced focus and mental clarity.',
        sleep: 'Deeper sleep and quicker recovery.',
        icon: '😊',
        challenges: 'Some lingering cravings and potential mood swings as your body adapts.',
        tips: 'Engage in physical activity, which can help balance mood. Make sure you’re eating balanced meals to reduce cravings.',
        range: [8, 14],
    },
    {
        days: '15-30 Days',
        physical: 'Clearer skin, less bloating.',
        mental: 'Consistent mood stabilization.',
        sleep: 'Regular, restful sleep patterns.',
        icon: '💤',
        challenges: 'Occasional cravings may still occur, especially in social settings or during stressful moments.',
        tips: 'Practice mindfulness or deep breathing to manage stress and cravings. Focus on the benefits you’ve already achieved.',
        range: [15, 30],
    },
    {
        days: 'Beyond 30 Days',
        physical: 'Sustained energy, potential weight loss.',
        mental: 'Stable mood and reduced anxiety.',
        sleep: 'Improved overall sleep quality.',
        icon: '🏋️',
        challenges: 'Minimal cravings, though old habits may still trigger occasional urges.',
        tips: 'Keep reinforcing new habits and reward yourself in healthy ways. Share your progress with others for added motivation.',
        range: [31, Infinity],
    },
];

interface HealthBenefitsTimelineProps {
    streak: number;
}

const HealthBenefitsTimeline: React.FC<HealthBenefitsTimelineProps> = ({ streak }) => {
    const [currentMilestoneIndex, setCurrentMilestoneIndex] = useState<number | null>(null);

    // Find the milestone that corresponds to the user's current streak
    useEffect(() => {
        const milestoneIndex = milestones.findIndex(
            (milestone) => streak >= milestone.range[0] && streak <= milestone.range[1]
        );
        setCurrentMilestoneIndex(milestoneIndex >= 0 ? milestoneIndex : null);
    }, [streak]);

    const handleMilestoneClick = (index: number) => {
        setCurrentMilestoneIndex(index === currentMilestoneIndex ? null : index);
    };

    const isStreakInRange = (index: number, milestone: Milestone) => {
        return streak >= milestone.range[0];
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-3xl mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-6 text-center">Health Benefits Timeline</h2>
            
            <div className="relative flex justify-between w-full mb-8">
                <div className="absolute top-1/4 left-5 right-6 h-1 bg-gray-200 rounded-full"></div> {/* Timeline Path */}
                
                {milestones.map((milestone, index) => (
                    <div key={index} className="flex-1 flex flex-col items-center">
                    <div
                        className={`w-12 h-12 rounded-full flex items-center justify-center cursor-pointer transition-transform transform
                        ${isStreakInRange(index, milestone) ? "bg-green-400 text-white scale-110" : "bg-gray-300 text-gray-500"}`}
                        onClick={() => handleMilestoneClick(index)}
                    >
                        <span className="text-xl">{milestone.icon}</span>
                    </div>
                    <p
                        className={`text-xs mt-2 font-semibold text-center ${
                        isStreakInRange(index, milestone) ? "text-green-700" : "text-gray-500"
                        }`}
                    >
                        {milestone.days}
                    </p>
                    </div>
                ))}
            </div>

            {/* Expanded Milestone Details */}
            {currentMilestoneIndex !== null && (
                <div className="transition-all duration-300 ease-in-out">
                    <h3 className="text-lg font-bold text-green-600 text-center">
                        {milestones[currentMilestoneIndex].days} Milestone
                    </h3>
                    
                    <div className="mt-4 space-y-2">
                        <div className="text-left">
                            <h4 className="text-md font-semibold text-green-500">Physical Health</h4>
                            <p className="text-gray-700">{milestones[currentMilestoneIndex].physical}</p>
                        </div>
                        <div className="text-left">
                            <h4 className="text-md font-semibold text-green-500">Mental Well-being</h4>
                            <p className="text-gray-700">{milestones[currentMilestoneIndex].mental}</p>
                        </div>
                        <div className="text-left">
                            <h4 className="text-md font-semibold text-green-500">Sleep Quality</h4>
                            <p className="text-gray-700">{milestones[currentMilestoneIndex].sleep}</p>
                        </div>
                    </div>

                    {/* Challenges and Tips */}
                    <div className="mt-4 text-left">
                        <h4 className="text-md font-semibold text-red-500">Challenges</h4>
                        <p className="text-gray-700">{milestones[currentMilestoneIndex].challenges}</p>
                        
                        <h4 className="text-md font-semibold text-blue-500 mt-4">Tips to Manage</h4>
                        <p className="text-gray-700">{milestones[currentMilestoneIndex].tips}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HealthBenefitsTimeline;