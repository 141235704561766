// components/LoginPage.tsx
import React, {useContext, useState} from 'react';
import { loginUser, LoginData } from '../services/loginService';
import { useNavigate } from "react-router-dom";
import {useAuth, UserContext} from "../contexts/AuthContext";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";

const LoginPage: React.FC = () => {
    const [formData, setFormData] = useState<LoginData>({ email: '', password: '' });
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const {token, login} = useAuth();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        const response = await loginUser(formData);
        if (response.token) {
            // navigate('/dashboard', { replace: true });
            window.location.href = '/dashboard';
        } else {
            setError(response.message);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-blue-50">
            <div className="relative w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
                <button
                    onClick={() => window.location.href = '/'}
                    className="absolute top-4 left-4 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full shadow-md hover:bg-gray-300 transition ease-in-out duration-150"
                    aria-label="Back"
                >
                    <ChevronLeftIcon className="w-6 h-6 text-gray-600"/>
                </button>
                <div className="flex justify-center mb-6">
                    <img src="/brain-logo.svg" alt="Stop Sugar Icon" className="w-20 h-20" />
                </div>

                <h2 className="text-2xl font-bold text-center text-blue-600 mb-6">Log In to Your Account</h2>

                {/* Error Message */}
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full mt-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full py-3 mt-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-200"
                    >
                        Log In
                    </button>
                </form>

                <p className="text-center text-gray-600 mt-4">
                    Don’t have an account? <a href="/register" className="text-blue-600 hover:underline">Sign Up</a>
                </p>
            </div>
        </div>
    );
};

export default LoginPage;