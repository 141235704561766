import React, {useContext, useState} from 'react';
import {
    ArrowLeftEndOnRectangleIcon,
    Bars3Icon,
    BookOpenIcon,
    ClipboardDocumentListIcon,
    CogIcon,
    HomeIcon, ScaleIcon, UserCircleIcon, UserIcon,
    UsersIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {UserContext} from "../contexts/AuthContext";
import {UserCircleOutline} from "heroicons-react";


const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { token, logout } = useContext(UserContext)!;

    const navigation = [
        { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
        { name: 'Community', href: '/feed', icon: UsersIcon },
        { name: 'Sugar Impact', href: '/products', icon: ScaleIcon }, // New entry
    ];

    return (
        <header className="flex justify-between items-center p-2 bg-white shadow-md">
            <div className="container mx-auto">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="flex items-center space-x-2">
                            <img src="/brain-logo.svg" alt="Stop Sugar Icon"
                                 className="w-8 h-8"/> {/* Adjusted size */}
                            <span className="text-2xl font-bold tracking-wide text-gray-800">Sugarless</span>
                        </div>
                        {/* Desktop Navigation */}
                        <nav className="flex md:ml-6 md:flex p-2 space-x-4">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="flex bg-gray-200 items-center px-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-900"
                                >
                                    <item.icon className="h-5 w-5 mr-1" aria-hidden="true"/>
                                    <p className='hidden md:flex'>{item.name}</p>
                                </a>
                            ))}
                        </nav>
                    </div>
                    {/* Right side - User Avatar */}
                    <div className="flex items-center">
                       
                        {/* User Dropdown */}
                        <div className="relative">
                            { (<>
                            <button
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-500 focus:ring-white"
                            >
                                <span className="sr-only">Open user menu</span>
                                <UserCircleIcon className={"w-8 h-8"}/>
                            </button>
                            {isDropdownOpen && (
                                <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50">
                                    <a href="#" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <UserIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                        Profile
                                    </a>
                                    <a href="/settings" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <CogIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                        Settings
                                    </a>
                                    <a href="#" onClick={logout} className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                        Logout
                                    </a>
                                </div>
                            )}
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile Navigation Menu */}
            {isMobileMenuOpen && (
                <nav className="md:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="flex items-center px-3 py-2 rounded-md text-base font-medium text-white hover:bg-green-700"
                            >
                                <item.icon className="h-5 w-5 mr-1" aria-hidden="true" />
                                {item.name}
                            </a>
                        ))}
                    </div>
                </nav>
            )}
        </header>
    );
};

export default Header;
