import React, { useState } from 'react';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const SettingsPage: React.FC = () => {
    const {user} = useAuth();
    // State to store the user input
    const [fullName, setFullName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('Select');
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');

    // Handlers for form submission (connect to backend API calls)
    const handleUpdatePersonalInfo = () => {
        console.log("Updating personal information...", { fullName, dateOfBirth, gender });
        api.put('/user/profile', { 'full_name':fullName, 'birth_date':dateOfBirth, gender }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.error(error);
        });
    };

    const handleUpdateAccountSecurity = () => {
        console.log("Updating account security...", { password });
        api.put('/user/change-password', { 'new_password':password, 'current_password':currentPassword }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <div className="min-h-screen bg-blue-50 p-6 font-sans">
            <main className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">

            {/* Personal Information Section */}
            <section className="p-6 bg-green-50 rounded-lg shadow-lg space-y-4">
                <h3 className="text-xl font-semibold text-green-600 mb-4">Personal Information</h3>
                <div>
                    <label className="block text-gray-600 font-medium mb-1">Full Name</label>
                    <input
                        type="text"
                        className="w-full px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-green-500"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="Enter your full name"
                    />
                </div>
                <div>
                    <label className="block text-gray-600 font-medium mb-1">Date of Birth</label>
                    <input
                        type="date"
                        className="w-full px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-green-500"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                </div>
                <div>
                    <label className="block text-gray-600 font-medium mb-1">Gender</label>
                    <select
                        className="w-full px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-green-500"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                    >
                        <option>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                        <option>Prefer not to say</option>
                    </select>
                </div>
                <button
                    onClick={handleUpdatePersonalInfo}
                    className="mt-4 w-full px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition text-center font-semibold"
                >
                    Update Personal Information
                </button>
            </section>

            {/* Account Security Section */}
            <section className="p-6 bg-green-50 rounded-lg shadow-lg space-y-4">
                <h3 className="text-xl font-semibold text-green-600 mb-4">Change Password</h3>
                <div>
                    <label className="block text-gray-600 font-medium mb-1">Current Password</label>
                    <input
                        type="password"
                        className="w-full px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-green-500"
                        value={password}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        placeholder="Enter your current password"
                    />
                </div>
                <div>
                    <label className="block text-gray-600 font-medium mb-1">Password</label>
                    <input
                        type="password"
                        className="w-full px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-green-500"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your new password"
                    />
                </div>
                <div>
                    <label className="block text-gray-600 font-medium mb-1">Confirm Password</label>
                    <input
                        type="password"
                        className="w-full px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:border-green-500"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Confirm your new password"
                    />
                </div>
                <button
                    onClick={handleUpdateAccountSecurity}
                    className="mt-4 w-full px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition text-center font-semibold"
                >
                    Update Account Security
                </button>
            </section>
            </main>
        </div>
    );
};

export default SettingsPage;
