// src/components/CelebrationCard.tsx
import React, { useState } from 'react';
import { Achievement } from '../types/Achievment';
import api from '../services/api';

interface CelebrationCardProps {
    achievement: Achievement;
}



const timeAgo = (date: Date) => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    const intervals = [
        { label: 'y', seconds: 31536000 },
        { label: 'mo', seconds: 2592000 },
        { label: 'w', seconds: 604800 },
        { label: 'd', seconds: 86400 },
        { label: 'h', seconds: 3600 },
        { label: 'm', seconds: 60 },
        { label: 's', seconds: 1 },
    ];

    for (const interval of intervals) {
        const count = Math.floor(seconds / interval.seconds);
        if (count >= 1) {
            return `${count}${interval.label} ago`;
        }
    }
    return 'just now';
};

const CelebrationCard: React.FC<CelebrationCardProps> = ({ achievement }) => {
    const [claps, setClaps] = useState(parseInt(achievement?.clap_count || '0'));

    const handleClap = () => {
        setClaps(claps + 1);
        api.post(`/achievements/${achievement.id}/clap`);
    };

    return (
        <div className="relative flex flex-col items-center  p-6 bg-gradient-to-br from-green-100 to-green-50 rounded-2xl shadow-lg w-full max-w-lg mx-auto mt-8">

            {/* Achievement Title */}
            <h3 className="text-xl font-bold text-green-800 text-center mb-2">{achievement.description}</h3>
            
            {/* Achievement Icon and Message */}
            <div className="flex flex-col items-center">
                <div className="w-16 h-16 bg-green-200 rounded-full flex items-center justify-center mb-3">
                    <span className="text-3xl">🎉</span>
                </div>
                <p className="text-center text-gray-700">
                    {achievement.user.username}
                    {achievement.achievement_type === 1 ? ' shared their streak,' : ' completed their first day without sugar.'}

                </p>
                <p className="text-xs text-gray-500 mt-1 italic text-center">
                    {achievement.achievement_type === 1 ? 'Shared ': 'Achieved'} {timeAgo(new Date(achievement.created_at))}
                </p>
            </div>

            {/* Clap Button */}
            <button
                onClick={handleClap}
                className="mt-4 flex items-center justify-center px-4 py-2 bg-green-500 text-white font-semibold rounded-full shadow-md hover:bg-green-600 transition transform hover:scale-105 focus:outline-none"
            >
                👏 Clap {claps}
            </button>
            
            {/* Decorative Elements */}
            <div className="absolute top-4 right-4 text-green-300 text-2xl animate-pulse">🍃</div>
        </div>
    );
};

export default CelebrationCard;
