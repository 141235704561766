// services/loginService.ts
import axios from 'axios';

export interface LoginData {
    email: string;
    password: string;
}

export interface LoginResponse {
    success: boolean;
    message: string;
    token?: string;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8081/api';


export const loginUser = async (data: LoginData): Promise<LoginResponse> => {
    try {
        const response = await axios.post(API_URL + "/login", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });

        console.log("Login response:", response.data.api_key);
        localStorage.setItem('token', response.data.api_key);

        return { success: true, message: "Login successful", token: response.data.api_key };
    } catch (error: any) {
        console.error("Login error:", error.response?.data || error.message);
        return { success: false, message: error.response?.data?.message || "Failed to log in. Please try again." };
    }
};