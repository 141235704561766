import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface HealthierAlternativesProps {
    selectedItems: string[];
    onClose: () => void;
    isOpen: boolean;
}

interface Alternative {
    alternative: string;
    benefits: string[];
    imageUrl: string;
}

const alternatives: { [key: string]: Alternative } = {
    "Coca-Cola (330ml)": {
        alternative: "Sparkling Water with Fresh Fruit Slices",
        benefits: ["No added sugar", "Zero calories", "Natural flavors"],
        imageUrl: "/images/sparkling-water-fruit.jpg",
    },
    "Coca-Cola (2L)": {
        alternative: "Sparkling Water with Fresh Fruit Slices",
        benefits: ["No added sugar", "Zero calories", "Natural flavors"],
        imageUrl: "/images/sparkling-water-fruit.jpg",
    },
    "Chocolate Bar (50g)": {
        alternative: "Dark Chocolate (70% Cocoa or Higher)",
        benefits: ["Lower sugar content", "Rich in antioxidants", "Less processed"],
        imageUrl: "/images/dark-chocolate.jpg",
    },
    "Fruit Yogurt (125g)": {
        alternative: "Plain Greek Yogurt with Fresh Berries",
        benefits: ["No added sugar", "High in protein", "Natural sweetness from fruit"],
        imageUrl: "/images/greek-yogurt-berries.jpg",
    },
    "Ice Cream (100g)": {
        alternative: "Frozen Banana Sorbet",
        benefits: ["No added sugar", "Rich in potassium", "Creamy texture without dairy"],
        imageUrl: "/images/banana-sorbet.jpg",
    },
    "Candy (30g)": {
        alternative: "Mixed Nuts and Dried Fruit",
        benefits: ["Natural sugars", "Provides healthy fats and fiber", "Satisfying crunch"],
        imageUrl: "/images/nuts-dried-fruit.jpg",
    },
    "Sugary Breakfast Cereal (40g)": {
        alternative: "Oatmeal with Fresh Fruit and Nuts",
        benefits: ["No added sugar", "High in fiber", "Keeps you full longer"],
        imageUrl: "/images/oatmeal-fruit-nuts.jpg",
    },
    "Sweetened Granola Bar (50g)": {
        alternative: "Homemade Energy Balls with Dates and Nuts",
        benefits: ["Natural sweetness", "No preservatives", "Customizable ingredients"],
        imageUrl: "/images/energy-balls.jpg",
    },
    "Flavored Coffee (300ml)": {
        alternative: "Black Coffee with a Dash of Cinnamon",
        benefits: ["No added sugar", "Zero calories", "Antioxidant-rich"],
        imageUrl: "/images/black-coffee-cinnamon.jpg",
    },
    "Soft Drink (500ml)": {
        alternative: "Herbal Iced Tea with Lemon",
        benefits: ["No added sugar", "Hydrating", "Rich in antioxidants"],
        imageUrl: "/images/herbal-iced-tea.jpg",
    },
    "Pastry (100g)": {
        alternative: "Whole Grain Toast with Avocado",
        benefits: ["No added sugar", "High in fiber", "Healthy fats"],
        imageUrl: "/images/avocado-toast.jpg",
    },
};

const HealthierAlternativesModal: React.FC<HealthierAlternativesProps> = ({ selectedItems, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-30" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-2xl font-bold text-green-600">Healthier Alternatives</h3>
                                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                                        <XMarkIcon className="w-6 h-6" />
                                    </button>
                                </div>
                                <div className="grid grid-cols-1 gap-6">
                                    {selectedItems.map((item, index) => {
                                        const alternativeData = alternatives[item];
                                        if (!alternativeData) return null;

                                        return (
                                            <div
                                                key={index}
                                                className="p-4 bg-green-50 rounded-lg shadow-md"
                                            >
                                                <h4 className="text-lg font-bold text-gray-700 mb-2">{item}</h4>

                                                <div className="flex justify-between items-center mb-4">
                                                    <div className="text-center">
                                                        <p className="text-gray-600 text-sm">Original</p>
                                                    </div>
                                                    <div className="text-center mx-4">
                                                        <p className="text-gray-500 text-sm">vs</p>
                                                    </div>
                                                    <div className="text-center">
                                                        <p className="text-green-600 text-sm font-semibold">
                                                            {alternativeData.alternative}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="bg-white p-4 rounded-md shadow-inner">
                                                    <h5 className="text-gray-700 font-semibold text-sm mb-2">Benefits:</h5>
                                                    <ul className="list-disc list-inside">
                                                        {alternativeData.benefits.map((benefit, i) => (
                                                            <li key={i} className="text-gray-600 text-xs">
                                                                {benefit}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default HealthierAlternativesModal;