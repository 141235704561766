import React, { useContext, useState } from 'react';
import { useAuth, UserContext } from "../contexts/AuthContext";
import { checkInUser } from "../services/checkInService";

const DailyCheckIn: React.FC = () => {
    const [showConfetti, setShowConfetti] = useState(false);
    const [checkedIn, setCheckedIn] = useState(false);
    const { user, updateUser } = useAuth();

    const handleCheckIn = () => {
        if (user?.checkedInToday || checkedIn) return;
        setCheckedIn(true);
        triggerConfetti();

        let response = checkInUser();
        response.then((res) => {
            console.log("Checked in successfully");
        });
    };

    const triggerConfetti = () => {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);
    };

    const renderCheckInContent = () => {
        if (parseInt(user?.streak || "0") === 0) {
            return (
                <div className="text-center space-y-4">
                    <p className="text-xl text-green-700 font-semibold">
                        Every journey starts with a single step…or, in this case, a single check-in! 🌟
                    </p>
                    <p className="text-green-600">
                        Go ahead, make that first move – you’ll never forget your first time! 😉
                    </p>
                </div>
            );
        } else {
            return (
                <div className="relative inline-flex flex-col items-center justify-center bg-yellow-100 border-2 border-yellow-300 rounded-full px-8 py-4 mb-6 shadow-lg">
                    {/* Sparkles */}
                    <div className="absolute top-0 left-1/3 text-yellow-500 animate-pulse">✨</div>
                    <div className="absolute top-0 right-1/3 text-yellow-500 animate-pulse">✨</div>
                    <div className="absolute bottom-1/2 left-2/3 text-yellow-500 animate-pulse">✨</div>

                    {/* Streak Information */}
                    <span className="text-5xl font-extrabold text-yellow-700">
                        {user?.streak || 0} Days
                    </span>
                    <span className="text-xl font-semibold text-yellow-600">Sugar-Free Champion 🎉</span>

                    {/* Bottom Ribbon */}
                    <div className="absolute -bottom-2 w-12 h-2 bg-yellow-300 rounded-b-full"></div>
                    <div className="absolute -bottom-4 w-4 h-2 bg-yellow-300 rounded-b-full transform -translate-x-1"></div>
                </div>
            );
        }
    };

    return (
        <section className="relative flex flex-col bg-gradient-to-r from-green-50 to-green-100 p-8 rounded-3xl shadow-2xl text-center overflow-hidden">
            {/* Personalized Greeting */}
            <h2 className="text-3xl font-extrabold mb-4 text-green-700 tracking-wide">
                Hi {user?.name || 'there'}, Ready for your Check-In?
            </h2>

            {renderCheckInContent()}

            <button
                className={(user?.checkedInToday ? `bg-gray-300 text-gray-700` : `bg-gradient-to-r from-green-500 to-teal-500 text-white shadow-md hover:from-green-600 hover:to-teal-600 hover:scale-105 transition transform duration-300 ease-in-out focus:outline-none`) + `relative overflow-hidden px-8 py-4 rounded-full font-semibold text-lg`}
                onClick={handleCheckIn}
                aria-label="Check in today"
            >
                <span className="relative z-10">{user?.checkedInToday ? `Checked In 🎉` : `Check In Today`}</span>
            </button>

            {/* Bubble Animations */}
            {parseInt(user?.streak || "0") > 0 && (
                <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
                    <div className="absolute top-0 left-1/4 w-6 h-6 bg-green-400 rounded-full opacity-75 animate-bounce animation-delay-200"></div>
                    <div className="absolute top-1/2 right-1/3 w-8 h-8 bg-teal-400 rounded-full opacity-60 animate-bounce animation-delay-400"></div>
                    <div className="absolute bottom-0 left-1/3 w-5 h-5 bg-yellow-400 rounded-full opacity-80 animate-bounce animation-delay-600"></div>
                    <div className="absolute bottom-1/4 right-1/4 w-7 h-7 bg-orange-400 rounded-full opacity-70 animate-bounce animation-delay-800"></div>
                </div>
            )}

            {showConfetti && (
                <div className="confetti-container">
                    {Array.from({ length: 100 }).map((_, index) => (
                        <div key={index} className="confetti"></div>
                    ))}
                </div>
            )}

            <style>{`
            .confetti-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 10;
}

.confetti {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: var(--confetti-color, #FFC107);
    opacity: 0.8;
    transform: translateY(-100vh);
    animation: confetti-fall linear infinite;
    animation-duration: calc(2s + 3s * var(--delay));
    border-radius: 2px;
}

.confetti:nth-child(odd) {
    --confetti-color: #FF5722;
}

.confetti:nth-child(even) {
    --confetti-color: #4CAF50;
}

.confetti:nth-child(3n) {
    --confetti-color: #03A9F4;
}

.confetti:nth-child(4n) {
    --confetti-color: #E91E63;
}

.confetti:nth-child(5n) {
    --confetti-color: #FFEB3B;
}

@keyframes confetti-fall {
    0% {
        transform: translateY(-100vh) rotate(0deg);
        left: calc(100% * var(--delay));
    }
    100% {
        transform: translateY(100vh) rotate(360deg);
        left: calc(100% * var(--delay));
    }
}
                .animation-delay-200 {
                    animation-delay: 0.2s;
                }
                .animation-delay-400 {
                    animation-delay: 0.4s;
                }
                .animation-delay-600 {
                    animation-delay: 0.6s;
                }
                .animation-delay-800 {
                    animation-delay: 0.8s;
                }
                @keyframes bounce {
                    0%, 100% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(-15px);
                    }
                }
                .animate-bounce {
                    animation: bounce 2s infinite;
                }
            `}</style>
        </section>
    );
};

export default DailyCheckIn;