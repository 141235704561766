// MainLayout.js
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navigation from "../components/Navigation";

const MainLayout = () => {
    const location = useLocation();

    // Define the routes where Navigation should be displayed
    const showNavigationRoutes = ['/dashboard', '/feed', '/settings', '/products'];

    // Check if the current path is in the list of routes to show Navigation
    const shouldShowNavigation = showNavigationRoutes.includes(location.pathname);

    return (
        <div>
            {shouldShowNavigation && <Navigation />}
            <Outlet />
        </div>
    );
};

export default MainLayout;
