import React from 'react';

interface ProgressBarProps {
    label: string;
    currentValue: number;
    targetValue: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ label, currentValue, targetValue }) => {
    // Calculate progress percentage, ensuring it doesn't exceed 100%
    const progress = targetValue > 0 ? Math.min((currentValue / targetValue) * 100, 100) : 0;

    return (
        <div className="mb-4">
            <p className="text-gray-700 font-medium">{label}</p>

            <div className="relative bg-gray-200 h-3 rounded-full mt-1 overflow-hidden">
                {/* Show progress fill if target is greater than 0 */}
                {targetValue > 0 ? (
                    <div
                        className="absolute left-0 top-0 h-full bg-green-500 rounded-full transition-all duration-300 ease-in-out"
                        style={{ width: `${progress}%` }}
                    ></div>
                ) : (
                    <div className="absolute inset-0 flex items-center justify-center text-xs text-gray-500">
                        Not started yet
                    </div>
                )}
            </div>

            {/* Show percentage or "Not started yet" */}
            <p className="text-right text-xs text-gray-500 mt-1">
                {targetValue > 0 ? `${Math.round(progress)}% completed` : "Not started yet"}
            </p>
        </div>
    );
};

export default ProgressBar;
