import React from 'react';
import api from '../services/api';

interface StreakProgressProps {
    streak: number;
    nextMilestone: number;
}

const StreakProgress: React.FC<StreakProgressProps> = ({ streak, nextMilestone }) => {

    const shareProgress = () => {
        api.post('/user/streak/share', { streak }).then(() => {
            alert("Your progress has been shared! 🚀");
        }).catch((err) => {
            console.error("Failed to share progress: ", err);
        }).then(() => {
            // Always hide the loading spinner
        });
    }


    // Get the motivational quote based on streak
    const getMotivationalQuote = () => {
        if (streak < 7) return "Every step counts! Keep pushing forward! 💪🚀";
        if (streak < 14) return "You're doing amazing! Stay strong and shine bright. 🌟✨";
        if (streak < 30) return "Your dedication is inspiring. Keep up the great work! 👏💼";
        if (streak < 90) return "Success is the sum of small efforts repeated daily. Keep at it! 📈🔄";
        return "You're on the right path. Keep going! 🛤️🌟";
    };

    // Render badges based on streak milestones
    const renderBadges = () => {
        return (
            <div className="badge-container flex space-x-2 mt-4">
                {streak >= 7 && <span className="badge badge-gold">7 Days 🎖️</span>}
                {streak >= 30 && <span className="badge badge-silver">30 Days 🏅</span>}
                {streak >= 90 && <span className="badge badge-bronze">90 Days 🏆</span>}
            </div>
        );
    };

    return (
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-4xl mx-auto flex flex-col items-center space-y-4 relative">
            <h2 className="text-2xl font-semibold text-gray-800">Your Streak Progress</h2>

            {/* Progress Ring around Streak */}
            <div className="relative">
                <div className="absolute inset-0 flex items-center justify-center">
                    <p className="streak-number">{streak}</p>
                </div>
                <svg className="w-24 h-24 text-blue-200" viewBox="0 0 36 36">
                    <circle
                        className="stroke-current"
                        cx="18" cy="18" r="16"
                        strokeWidth="4"
                        fill="none"
                        style={{ strokeDasharray: 100, strokeDashoffset: 100 - (streak / nextMilestone) * 100 }}
                    />
                </svg>
            </div>

            {/* Motivational Quote */}
            <p className="text-center text-gray-500 italic">{getMotivationalQuote()}</p>

            {/* Achievement Bar */}
            <div className="w-full bg-gray-200 rounded-full h-2 mt-4">
                <div
                    className="bg-green-500 h-2 rounded-full"
                    style={{ width: `${(streak / nextMilestone) * 100}%` }}
                ></div>
            </div>
            <p className="text-sm text-gray-400 mt-1">Next milestone: {nextMilestone} days</p>

            {/* Badges for Milestones */}
            {renderBadges()}

            {/* Optional Share Button */}
            <button onClick={shareProgress} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none transition">
                Share your progress 🚀
            </button>

            {/* Custom styles for streak number and badges */}
            <style>{`
                .streak-number {
                    font-size: 3rem;
                    font-weight: bold;
                    color: #FFA726;
                    animation: pulse 1.5s infinite;
                }
                
                .badge-container {
                    display: flex;
                    gap: 8px;
                    justify-content: center;
                }
                
                .badge {
                    padding: 4px 8px;
                    border-radius: 12px;
                    font-size: 0.85rem;
                    color: #fff;
                    font-weight: bold;
                }
                
                .badge-gold { background-color: #FFD700; }
                .badge-silver { background-color: #C0C0C0; }
                .badge-bronze { background-color: #CD7F32; }

                @keyframes pulse {
                    0%, 100% { transform: scale(1); }
                    50% { transform: scale(1.1); }
                }
            `}</style>
        </div>
    );
};

export default StreakProgress;