import React, { useEffect, useState } from 'react';

const EnergyDayChart: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const timeSlots = [
    { time: '6-9 AM', label: 'Morning 🌅', description: 'Exercise or mindfulness. Light breakfast.', energy: 70, focus: 60, dos: ['Stretch or light exercise', 'Hydrate with water'], donts: ['Skip breakfast', 'Check emails immediately'], gradient: 'from-green-50 to-yellow-50' },
    { time: '9-11 AM', label: 'Cognitive Peak 🧠', description: 'Deep work and creativity.', energy: 80, focus: 90, dos: ['Focus on complex tasks', 'Plan your day strategically'], donts: ['Multitask', 'Social media distractions'], gradient: 'from-teal-50 to-blue-50' },
    { time: '11 AM - 1 PM', label: 'Sustained Focus 🔄', description: 'Maintain productivity with routine tasks.', energy: 65, focus: 70, dos: ['Continue productive tasks', 'Tackle lighter tasks'], donts: ['Start complex new tasks', 'Overload with meetings'], gradient: 'from-green-50 to-teal-50' },
    { time: '1-3 PM', label: 'Post-Lunch Dip 🍽️', description: 'Take a walk or power nap.', energy: 40, focus: 30, dos: ['Take a break or nap', 'Go for a light walk'], donts: ['Heavy meals', 'High-sugar snacks'], gradient: 'from-yellow-50 to-orange-50' },
    { time: '3-6 PM', label: 'Afternoon Peak 🌞', description: 'Ideal for meetings or brainstorming.', energy: 75, focus: 80, dos: ['Hold meetings', 'Balanced snack'], donts: ['Skip breaks', 'Excessive caffeine'], gradient: 'from-blue-50 to-green-50' },
    { time: '6-9 PM', label: 'Evening 🌙', description: 'Relaxing activities.', energy: 50, focus: 50, dos: ['Organize for next day', 'Engage in relaxing activities'], donts: ['High-energy activities', 'Heavy late meals'], gradient: 'from-orange-50 to-yellow-50' },
    { time: '9-11 PM', label: 'Pre-Sleep Routine 🌌', description: 'Prepare for quality sleep.', energy: 20, focus: 20, dos: ['Create a sleep routine', 'Avoid screens before bed'], donts: ['Consume caffeine', 'Late-night snacking'], gradient: 'from-gray-50 to-blue-50' },
    { time: '11 PM - 6 AM', label: 'Rest and Recovery 🛌', description: 'Sleep and recharge.', energy: 10, focus: 5, dos: ['Aim for uninterrupted sleep', 'Keep room dark and cool'], donts: ['Stay up late', 'Use electronics in bed'], gradient: 'from-blue-50 to-gray-100' }
  ];

  const getCurrentTimeSlot = () => {
    const hours = currentTime.getHours();
    if (hours >= 6 && hours < 9) return 0; // 6-9 AM
    if (hours >= 9 && hours < 11) return 1; // 9-11 AM
    if (hours >= 11 && hours < 13) return 2; // 11 AM - 1 PM
    if (hours >= 13 && hours < 15) return 3; // 1-3 PM
    if (hours >= 15 && hours < 18) return 4; // 3-6 PM
    if (hours >= 18 && hours < 21) return 5; // 6-9 PM
    if (hours >= 21 && hours < 23) return 6; // 9-11 PM
    return 7; // 11 PM - 6 AM (overnight)
  };

  const currentSlotIndex = getCurrentTimeSlot();

  useEffect(() => {
    setExpandedIndex(currentSlotIndex); // Expand the current time slot on load
  }, [currentSlotIndex]);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold text-gray-800 mb-2">Energy Rhythm</h2>
      <div className="flex flex-col space-y-2">
        {timeSlots.map((slot, index) => (
          <div
            key={index}
            className={`p-3 rounded-lg bg-gradient-to-br ${slot.gradient} transition-all duration-300 ${
              currentSlotIndex === index ? 'border-l-4 border-blue-500' : ''
            }`}
          >
            <div className="flex justify-between items-center">
              <div className="flex flex-col space-y-1">
                <span className="text-sm font-semibold">{slot.time}</span>
                <span className="text-gray-700 text-base font-medium">{slot.label}</span>
              </div>
              <button
                onClick={() => toggleExpand(index)}
                className="text-blue-500 text-xs focus:outline-none"
              >
                {expandedIndex === index ? 'Hide Tips' : 'Show Tips'}
              </button>
            </div>
            
            {/* Description and Energy/Focus Bars */}
            {expandedIndex === index && (
              <div className="mt-2 text-xs text-gray-700">
                <p className="mb-2">{slot.description}</p>
                
                {/* Energy and Focus Circles */}
                <div className="flex items-center space-x-2 mb-2">
                  <div className="flex items-center space-x-1">
                    <div className="relative w-6 h-6">
                      <svg className="w-full h-full" viewBox="0 0 36 36">
                        <circle
                          className="text-gray-300"
                          strokeWidth="3"
                          stroke="currentColor"
                          fill="transparent"
                          r="15.915"
                          cx="18"
                          cy="18"
                        />
                        <circle
                          className="text-green-500"
                          strokeWidth="3"
                          strokeDasharray="100"
                          strokeDashoffset={100 - slot.energy}
                          strokeLinecap="round"
                          stroke="currentColor"
                          fill="transparent"
                          r="15.915"
                          cx="18"
                          cy="18"
                        />
                      </svg>
                      <span className="absolute inset-0 flex items-center justify-center text-[0.4rem] font-bold leading-none">
                        {slot.energy}%
                      </span>
                    </div>
                    <span className="text-xs text-gray-600">Energy</span>
                  </div>

                  <div className="flex items-center space-x-1">
                    <div className="relative w-6 h-6">
                      <svg className="w-full h-full" viewBox="0 0 36 36">
                        <circle
                          className="text-gray-300"
                          strokeWidth="3"
                          stroke="currentColor"
                          fill="transparent"
                          r="15.915"
                          cx="18"
                          cy="18"
                        />
                        <circle
                          className="text-blue-500"
                          strokeWidth="3"
                          strokeDasharray="100"
                          strokeDashoffset={100 - slot.focus}
                          strokeLinecap="round"
                          stroke="currentColor"
                          fill="transparent"
                          r="15.915"
                          cx="18"
                          cy="18"
                        />
                      </svg>
                      <span className="absolute inset-0 flex items-center justify-center text-[0.4rem] font-bold leading-none">
                        {slot.focus}%
                      </span>
                    </div>
                    <span className="text-xs text-gray-600">Focus</span>
                  </div>
                </div>

                {/* Do's and Don'ts */}
                <div className="text-green-600 font-semibold">Do's:</div>
                <ul className="list-disc ml-4 mt-1 space-y-1">
                  {slot.dos.map((doItem, i) => (
                    <li key={i}>✅ {doItem}</li>
                  ))}
                </ul>
                <div className="text-red-600 font-semibold mt-2">Don'ts:</div>
                <ul className="list-disc ml-4 mt-1 space-y-1">
                  {slot.donts.map((dontItem, i) => (
                    <li key={i}>❌ {dontItem}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnergyDayChart;