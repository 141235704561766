import axios from 'axios';
import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
});

export interface RegisterData {
    username: string;
    password: string;
    email: string;
}

export interface RegisterResponse {
    success: boolean;
    message: string;
}

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8081/api";

export const registerUser = async (data: RegisterData): Promise<RegisterResponse> => {
    try {
        const response = await axios.post<RegisterResponse>(API_URL + "/users", data, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });

        return { success: true, message: response.data.message || "Registration successful!" };
    } catch (error: any) {
        console.error("Registration error:", error.message);
        return { success: false, message: error.response?.data?.message || error.message };
    }
};