import React from 'react';
import { calculateSugarImpact, SugarMetrics } from '../../services/sugarCalculator';
import { FireIcon, InformationCircleIcon, LightBulbIcon, ScaleIcon } from '@heroicons/react/24/outline';

interface TopItemsDisplayProps {
    onAddItem: (item: string) => void;
    selectedItems: string[];
}

const items: SugarMetrics[] = [
    { 
        name: "Coca-Cola (330ml)", 
        sugar: 35, 
        calories: 139, 
        volume: 330, 
        energy: { kJ: 585, kcal: 139 },
        carbohydrates: 35, 
        protein: 0, 
        fat: 0, 
        saturatedFat: 0, 
        salt: 0 
    },
    { 
        name: "Coca-Cola (2L)", 
        sugar: 212, 
        calories: 842, 
        volume: 2000, 
        energy: { kJ: 3528, kcal: 842 },
        carbohydrates: 212, 
        protein: 0, 
        fat: 0, 
        saturatedFat: 0, 
        salt: 0 
    },
    { 
        name: "Chocolate Bar (50g)", 
        sugar: 24, 
        calories: 270, 
        weight: 50, 
        energy: { kJ: 1130, kcal: 270 },
        fat: 14, 
        saturatedFat: 8, 
        carbohydrates: 29, 
        protein: 2.5, 
        salt: 0.1 
    },
    { 
        name: "Fruit Yogurt (125g)", 
        sugar: 19, 
        calories: 150, 
        weight: 125, 
        energy: { kJ: 627, kcal: 150 },
        fat: 4, 
        saturatedFat: 2.5, 
        carbohydrates: 20, 
        protein: 5, 
        salt: 0.1 
    },
    { 
        name: "Ice Cream (100g)", 
        sugar: 21, 
        calories: 207, 
        weight: 100, 
        energy: { kJ: 868, kcal: 207 },
        fat: 11, 
        saturatedFat: 6.5, 
        carbohydrates: 24, 
        protein: 3.5, 
        salt: 0.1 
    },
    { 
        name: "Candy (30g)", 
        sugar: 18, 
        calories: 120, 
        weight: 30, 
        energy: { kJ: 502, kcal: 120 },
        fat: 2, 
        saturatedFat: 1.5, 
        carbohydrates: 24, 
        protein: 0.5, 
        salt: 0 
    },
    { 
        name: "Sugary Breakfast Cereal (40g)", 
        sugar: 10, 
        calories: 160, 
        weight: 40, 
        energy: { kJ: 670, kcal: 160 },
        fat: 1.5, 
        saturatedFat: 0.5, 
        carbohydrates: 32, 
        protein: 2.5, 
        salt: 0.3 
    },
    { 
        name: "Sweetened Granola Bar (50g)", 
        sugar: 12, 
        calories: 200, 
        weight: 50, 
        energy: { kJ: 837, kcal: 200 },
        fat: 6, 
        saturatedFat: 2, 
        carbohydrates: 30, 
        protein: 4, 
        salt: 0.2 
    },
    { 
        name: "Flavored Coffee (300ml)", 
        sugar: 25, 
        calories: 180, 
        volume: 300, 
        energy: { kJ: 753, kcal: 180 },
        fat: 6, 
        saturatedFat: 4, 
        carbohydrates: 26, 
        protein: 2, 
        salt: 0.1 
    },
    { 
        name: "Soft Drink (500ml)", 
        sugar: 50, 
        calories: 210, 
        volume: 500, 
        energy: { kJ: 880, kcal: 210 },
        carbohydrates: 50, 
        protein: 0, 
        fat: 0, 
        saturatedFat: 0, 
        salt: 0 
    },
    { 
        name: "Pastry (100g)", 
        sugar: 18, 
        calories: 300, 
        weight: 100, 
        energy: { kJ: 1255, kcal: 300 },
        fat: 15, 
        saturatedFat: 8, 
        carbohydrates: 35, 
        protein: 5, 
        salt: 0.5 
    }
];  

const calculateExerciseTime = (calories: number) => {
    const walkingTime = (calories / 4).toFixed(0);
    const runningTime = (calories / 10).toFixed(0);
    const cyclingTime = (calories / 8).toFixed(0);
    return { walkingTime, runningTime, cyclingTime };
};

const TopItemsDisplay: React.FC<TopItemsDisplayProps> = ({ onAddItem, selectedItems }) => {
    return (
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {items.map((item, index) => {
                const impact = calculateSugarImpact(item);
                const exerciseTimes = calculateExerciseTime(item.calories);

                return (
                    <div
                        key={index}
                        className="p-3 bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow relative"
                    >
                        {/* Item Name */}
                        <div className="flex items-center justify-between">
                        <h3 className="text-xl mb-2 font-semibold text-center text-gray-800">{item.name}</h3>
                        <button
                            onClick={() => onAddItem(item.name)}
                            className="text-blue-500 hover:text-blue-600 focus:outline-none"
                            aria-label="Show healthier alternative"
                        >
                            <LightBulbIcon className="w-5 h-5" />
                        </button>
                        </div>

                        <div className='flex flex-row items-center space-x-2'>
                            {/* Sugar Content with Visual and Tooltip */}
                            <div className="grow flex flex-row items-center mb-2 space-x-2">

                                <ScaleIcon className="w-6 h-6 text-yellow-600" />
                                <p className="text-lg font-semibold text-gray-700">
                                    {impact.sugar}g Sugar (~{impact.teaspoons} tsp)
                                </p>
                                <InformationCircleIcon
                                    className="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
                                    title="1 teaspoon is roughly 4g of sugar"
                                />

                            </div>

                            <div>
                                <span
                                    className={`inline-block px-2 py-1 rounded-full text-xs font-semibold ${
                                        impact.sugarLevel === "Very High"
                                            ? "bg-red-200 text-red-800"
                                            : impact.sugarLevel === "High"
                                            ? "bg-orange-200 text-orange-800"
                                            : impact.sugarLevel === "Moderate"
                                            ? "bg-yellow-200 text-yellow-800"
                                            : "bg-green-200 text-green-800"
                                    }`}
                                >
                                    {impact.sugarLevel}
                                </span>
                            </div>
                        </div>

                        <div className='flex flex-row items-center space-x-2'>
                        {/* Calories with Visual */}
                        <div className="grow flex items-center space-x-2 mt-2">
                            <FireIcon className="w-6 h-6 text-red-600" />
                            <p className="text-gray-600">
                                {impact.calories} kcal (~{impact.kJ} kJ)
                            </p>
                        </div>

                        {/* Volume or Weight */}
                        {item.volume ? (
                            <p className="text-gray-600 mt-2">Volume: {item.volume}ml</p>
                        ) : (
                            <p className="text-gray-600 mt-2">Weight: {item.weight}g</p>
                        )}

                        </div>

                        {/* Health Impact Indicator */}
                        <div className="mt-4 p-2 rounded-lg bg-gray-100 text-gray-700 text-sm font-medium">
                            {impact.insulinDemand === "Very High Demand" ? (
                                <p>⚠️ Very high insulin demand; consuming such quantities frequently can lead to insulin resistance and long-term health risks.</p>
                            ) : impact.insulinDemand === "High Demand" ? (
                                <p>⚠️ High insulin demand; may cause energy crashes and heightened cravings if consumed regularly.</p>
                            ) : impact.insulinDemand === "Moderate Demand" ? (
                                <p>⚠️ Moderate insulin demand; manageable for most, but best consumed in moderation.</p>
                            ) : (
                                <p>✅ Low insulin demand; safer for maintaining stable blood sugar and energy levels.</p>
                            )}
                        </div>

                        
                        {/* Exercise Equivalence */}
                        <div className="mt-2 bg-gray-50 p-2 rounded-md">
                            <p className="text-sm font-semibold text-gray-700 ">To burn these calories:</p>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-1">
                                    🚶‍♀️           
                                    <span className="text-xs text-gray-700">{exerciseTimes.walkingTime} min walking</span>
                                </div>
                                <div className="flex items-center space-x-1">
                                    🏃🏾‍♀️
                                    <span className="text-xs text-gray-700">{exerciseTimes.runningTime} min running</span>
                                </div>
                                <div className="flex items-center space-x-1">
                                    🚴🏼‍♂️
                                    <span className="text-xs text-gray-700">{exerciseTimes.cyclingTime} min cycling</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </section>
    );
};

export default TopItemsDisplay;