// src/components/Feed.tsx
import React, { useEffect, useState } from 'react';
import { Achievement } from '../types/Achievment';
import AchievementCard from '../components/AchievmentCardComponent';
import api from '../services/api';

const Feed: React.FC = () => {
    const [achievements, setAchievements] = useState<Achievement[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAchievements = async () => {
            try {
                const response = await api.get('/achievements');
                setAchievements(response.data?.achievements || []);
            } catch (err) {
                setError((err as Error)?.message || "An error occurred. Please try again.");
            }
        };

        fetchAchievements();
    }, []);

    if (error) {
        return <p className="text-red-500">Error: {error}</p>;
    }

    return (
        <div className="min-h-screen bg-blue-50 p-6 flex flex-col items-center font-sans text-gray-800">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Community updates</h2>
            {achievements.length > 0 ? (
                achievements.map((achievement) => (
                    <div className="space-y-4 w-full">
                        <AchievementCard key={achievement.id} achievement={achievement} />
                        <div className="text-center text-2xl">🌱</div>
                    </div>
                ))
            ) : (
                <p className="text-gray-500">No updates yet!</p>
            )}
        </div>
    );
};

export default Feed;
