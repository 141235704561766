// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {getCurrentStreak} from "../services/checkInService";

const ProtectedRoute: React.FC = () => {
    const { token, user, updateUser } = useAuth();

    if (!user && token !== null) {
        getCurrentStreak().then((res) => {
            updateUser({
                id: res.user_id || 0,
                name: res.full_name || res.username,
                email: null,
                streak: res.streak + "",
                lastCheckinDate: res.lastCheckInDate || null,
                totalDays: res.totalCheckIns + "",
                checkedInToday: res.checkedInToday || null,
            });
        });
    }

    return token ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
